<template>
    <div class="global-search">
        <input
            type="text"
            placeholder="พิมพ์คำค้นหาที่คุณต้องการค้นหา"
            v-model="keyword"
            @keyup.enter="search()"
            @keyup="handleInput($event)"
            @focus="handleFocus()"
        />
        <b-icon icon="search" @click="search()" v-if="!isLoadKeyword"></b-icon> 
        <div class="autocomplete-list" v-if="isShowAutoComplete">
            <div class="wrapper">
                <div class="item" v-for="(item, index) in autoCompleteList" :key="index" @click="selectKeyword(item)">
                    <span v-html="highlightKeyword(item.text)"></span>
                </div>
            </div>
        </div>
        <loader-spinner
            v-if="isLoadKeyword"
            class="load"
            sizeWidth="25px"
            sizeHeight="25px">
        </loader-spinner>
    </div>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import LoaderSpinner from '@/components/utility/LoaderSpinner';
import HomeService from '@/services/homeService';

export default {
    components: {
        LoaderSpinner
    },
	data() {
		return {
            keyword: '',
            autoCompleteList: [],
            isLoadKeyword: false,
            isShowAutoComplete: false
		};
	},
    mounted() {
        document.addEventListener('click', this.handleOutsideClick);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleOutsideClick);
    },
    methods: {
        handleFocus() {
            if (this.keyword.length >= 3) {
                this.lookupKeyword();
            } else {
                this.autoCompleteList = [];
            }
        },
        handleOutsideClick(e) {
            if (!this.$el.contains(e.target)) {
                this.isShowAutoComplete = false;
            }
        },
        selectKeyword(item) {
            this.keyword = item.text;
            this.isShowAutoComplete = false;
        },
        highlightKeyword(text) {
            if (!this.keyword) return text; 

            const regex = new RegExp(`(${this.keyword})`, 'gi');

            return text.replace(regex, '<strong>$1</strong>');
        },
        async handleInput(e) {
            e = e || window.event;
            this.autoCompleteList = [];

            if (this.keyword.length >= 3
                && e.key !== 'Enter'
                && e.keyCode !== 13
                && e.keyCode !== 38
                && e.keyCode !== 40
                && e.keyCode !== 37
                && e.keyCode !== 39) {
                await this.lookupKeyword();
            } else {
                this.autoCompleteList = [];
            }
        },
        async lookupKeyword() {
            this.isLoadKeyword = true;

            const result = await HomeService.getKeywordList(this.keyword);

            if (result.data && result.data.length > 0) {
                this.autoCompleteList = result.data;
                this.isShowAutoComplete = true;
            } else {
                this.autoCompleteList = [];
            }

            this.isLoadKeyword = false;
        },
        search() {
            let tab = Helper.getGlobalSearchTab();
            const autoComplete = this.autoCompleteList.find(x => x.text === this.keyword);
            const newParam = 'k=' + this.keyword + '&t=' + (autoComplete ? autoComplete.tab : tab);
            const currentParam = location.href.substring(location.href.indexOf('?'));

            if (currentParam !== newParam) {
                this.$router.push(RouterPath.SEARCH + '?' + newParam);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';
.global-search {
    position: relative;

    input {
        display: block;
        width: 100%;
        font-size: 15px;
        background: #fff;
        border: 1px solid #707070;
        @include borderRadius(10px);
        padding: 8px 35px 8px 10px;

        @media only screen and (max-width: $screenLarge) {
            padding: 6px 30px 6px 10px;
        }
    }

    .bi {
        position: absolute;
        color: #000;
        font-size: 26px;
        top: 7px;
        right: 10px;
        cursor: pointer;
        z-index: 5;

        &:hover {
            opacity: 0.7;
        }

        @media only screen and (max-width: $screenLarge) {
            font-size: 20px;
        }
    }

    .load {
        position: absolute;
        top: 7px;
        right: 10px;
        z-index: 5;
    }

    .autocomplete-list {
        position: absolute;
        margin-top: 5px;
        width: 100%;
        max-height: 400px;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        overflow-y: auto;
        z-index: 999;
        @include boxShadow(0 0 10px rgba(0, 0, 0, 0.15));

        .wrapper {
            background: #fff;
            overflow: hidden;
            @include borderRadius(5px);

            .item {
                cursor: pointer;
                padding: 7px 16px;
                font-size: 16px;
                border-bottom: 1px solid #ccc;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    color: #fff;
                    background-color: #0c5688;
                }

                strong {
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>