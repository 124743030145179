<template>
    <div class="template-default">
        <div class="template-top">
            <header class="site-header">
                <div class="container">
                    <div class="d-none d-lg-block">
                        <div class="desktop">
                            <a href="/" title="Yellowtire.com" class="logo">
                                <img src="@/assets/logo.webp" alt="Yellow Tires" title="Yellow Tires" />
                            </a>
                            <div class="top-content">
                                <div class="search">
                                    <div class="links">
                                        <section>
                                            <div class="dropdown-link" @mouseover="isShowTireBrandMenu = true" @mouseout="isShowTireBrandMenu = false">
                                                <button>ราคายางรถยนต์</button>
                                                <div class="sub-menu" :class="{ 'show': isShowTireBrandMenu }">
                                                    <router-link :to="routerPath.TIRE" title="ราคายางรถยนต์">ราคายางรถยนต์</router-link>
                                                    <router-link :to="helper.replaceProductParam(routerPath.TIRE_BRAND, item.param)" v-for="(item, index) in brandMenu" :key="index" :title="item.title">
                                                        ยางรถยนต์ {{ item.title }}
                                                    </router-link>
                                                </div>
                                            </div>
                                            <div class="dropdown-link" @mouseover="isShowTireDiameterMenu = true" @mouseout="isShowTireDiameterMenu = false">
                                                <button>ขอบยาง</button>
                                                <div class="sub-menu" :class="{ 'show': isShowTireDiameterMenu }">
                                                    <router-link :to="routerPath.TIRE + '?' + item.param"
                                                        :title="item.text"
                                                        v-for="(item, index) in tireDiameterMenus" :key="'td' + index">
                                                        {{ item.text }}
                                                    </router-link>
                                                </div>
                                            </div>
                                            <div class="dropdown-link" @mouseover="isShowTireTypeMenu = true" @mouseout="isShowTireTypeMenu = false">
                                                <button>ประเภทยาง</button>
                                                <div class="sub-menu" :class="{ 'show': isShowTireTypeMenu }">
                                                    <router-link :to="routerPath.TIRE + '?' + item.param"
                                                        :title="item.text"
                                                        v-for="(item, index) in tireTypeMenus" :key="'tp' + index">
                                                        {{ item.text }}
                                                    </router-link>
                                                </div>
                                            </div>
                                            <router-link :to="routerPath.STORE" title="ร้านยางที่ใกล้">ร้านยางใกล้ฉัน</router-link>
                                            <div class="dropdown-link" @mouseover="isShowPromotionMenu = true" @mouseout="isShowPromotionMenu = false">
                                                <button>โปรโมชั่นยาง</button>
                                                <div class="sub-menu" :class="{ 'show': isShowPromotionMenu }">
                                                    <router-link :to="routerPath.TIRE + '?' + item.param"
                                                        :title="item.text"
                                                        v-for="(item, index) in promotionMenus" :key="'pr' + index">
                                                        {{ item.text }}
                                                    </router-link>
                                                    <router-link :to="routerPath.PROMOTION" title="โปรยางล่าสุด">โปรยางล่าสุด</router-link>
                                                </div>
                                            </div>
                                            <router-link :to="routerPath.ARTICLE" title="บทความยานยนต์">บทความ/ข่าวสาร</router-link>
                                            <router-link :to="routerPath.CONTACT" title="ติดต่อเรา">ติดต่อเรา</router-link>
                                        </section>
                                    </div>

                                    <global-search></global-search>
                                </div>

                                <div class="menu">
                                    <div class="user">
                                        <router-link :to="routerPath.LOGIN" title="ลงชื่อเข้าใช้" v-if="!isUserLogin()">ลงชื่อเข้าใช้</router-link>
                                        <router-link :to="routerPath.MY_PROFILE" title="ข้อมูลลูกค้า" v-if="isUserLogin()"><b-icon icon="person" class="icon"></b-icon> {{ displayName }}</router-link>
                                    </div>
                                    <div class="gadget">
                                        <!-- <router-link :to="routerPath.TIRE_CALCULATOR" title="คำนวณขนาดยาง"><b-icon icon="calculator" class="icon"></b-icon></router-link> -->
                                        <router-link :to="routerPath.CART" title="ตะกร้าสินค้า" class="cart-icon">
                                            <b-icon icon="cart4" class="icon"></b-icon>
                                            <div class="dot" v-if="isShowCartIcon"></div>
                                        </router-link>
                                        <router-link :to="routerPath.MY_ORDER" title="คำสั่งซื้อของฉัน" v-if="isUserLogin()"><b-icon icon="person" class="icon"></b-icon></router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-lg-none">
                        <div class="mobile">
                            <div class="group-left">
                                <b-icon icon="justify" @click="toggleMobileMenu()" class="menu-icon"></b-icon>
                                <router-link to="/" title="Yellowtire.com" class="logo">
                                    <img src="@/assets/logo.webp" alt="Yellow Tires" title="Yellow Tires" />
                                </router-link>
                            </div>
                            <div class="gadget">
                                <!-- <router-link :to="routerPath.TIRE_CALCULATOR" title="คำนวณขนาดยาง"><b-icon icon="calculator" class="icon"></b-icon></router-link> -->
                                <router-link :to="routerPath.CART" title="ตะกร้าสินค้า" class="cart-icon">
                                    <b-icon icon="cart4" class="icon"></b-icon>
                                    <div class="dot" v-if="isShowCartIcon"></div>
                                </router-link>
                                <router-link :to="routerPath.MY_ORDER" title="คำสั่งซื้อของฉัน"><b-icon icon="person" class="icon"></b-icon></router-link>
                            </div>
                        </div>

                        <div class="mobile-menu" v-if="isShowMobileMenu">
                            <div class="box-menu">
                                <perfect-scrollbar>
                                    <section class="close-menu">
                                        <b-icon icon="x" @click="toggleMobileMenu()" class="menu-icon"></b-icon>
                                    </section>
                                    <section class="user">
                                        <router-link :to="routerPath.LOGIN" @click.native="toggleMobileMenu()" title="ลงชื่อเข้าใช้" v-if="!isUserLogin()">ลงชื่อเข้าใช้</router-link>
                                        <router-link :to="routerPath.MY_PROFILE" title="ข้อมูลลูกค้า" v-if="isUserLogin()"><b-icon icon="person" class="icon"></b-icon> {{ displayName }}</router-link>
                                    </section>
                                    <section class="general">
                                        <router-link to="/" @click.native="toggleMobileMenu()" title="Yellowtire.com">หน้าหลัก</router-link>
                                        <button @click="isLoadMoreTireBrandMenu = false">ยี่ห้อยาง <b-icon icon="chevron-up" class="toggle-icon" v-if="isLoadMoreTireBrandMenu"></b-icon></button>
                                        <div class="toggle-menu show">
                                            <router-link :to="helper.replaceProductParam(routerPath.TIRE_BRAND, item.param)"
                                                :title="item.title"
                                                @click.native="toggleMobileMenu()"
                                                v-for="(item, index) in getBrandMenu()" :key="'tb' + index">
                                                ยางรถยนต์ {{ item.title }}
                                            </router-link>
                                            <button class="load-more" v-if="!isLoadMoreTireBrandMenu"
                                                @click="isLoadMoreTireBrandMenu = true">
                                                ดูยี่ห้ออื่นๆเพิ่มเติม <b-icon icon="chevron-down" class="toggle-icon"></b-icon>
                                            </button>
                                        </div>
                                        <button @click="isShowTireDiameterMenu = !isShowTireDiameterMenu">ขอบยาง <b-icon icon="chevron-down" class="toggle-icon"></b-icon></button>
                                        <div class="toggle-menu" :class="{ 'show': isShowTireDiameterMenu }">
                                            <router-link :to="routerPath.TIRE + '?' + item.param"
                                                :title="item.text"
                                                @click.native="toggleMobileMenu()"
                                                v-for="(item, index) in tireDiameterMenus" :key="'td' + index">
                                                {{ item.text }}
                                            </router-link>
                                        </div>
                                        <button @click="isShowTireTypeMenu = !isShowTireTypeMenu">ประเภทยาง <b-icon icon="chevron-down" class="toggle-icon"></b-icon></button>
                                        <div class="toggle-menu" :class="{ 'show': isShowTireTypeMenu }">
                                            <router-link :to="routerPath.TIRE + '?' + item.param"
                                                :title="item.text"
                                                @click.native="toggleMobileMenu()"
                                                v-for="(item, index) in tireTypeMenus" :key="'tp' + index">
                                                {{ item.text }}
                                            </router-link>
                                        </div>
                                        <router-link :to="routerPath.STORE" @click.native="toggleMobileMenu()" title="ค้นหาร้านยางที่ใกล้">ร้านยางใกล้ฉัน</router-link>
                                        <button @click="isShowPromotionMenu = !isShowPromotionMenu">โปรยางรถยนต์ <b-icon icon="chevron-down" class="toggle-icon"></b-icon></button>
                                        <div class="toggle-menu" :class="{ 'show': isShowPromotionMenu }">
                                            <router-link :to="routerPath.TIRE + '?' + item.param"
                                                :title="item.text"
                                                @click.native="toggleMobileMenu()"
                                                v-for="(item, index) in promotionMenus" :key="'pr' + index">
                                                {{ item.text }}
                                            </router-link>
                                            <router-link :to="routerPath.PROMOTION" @click.native="toggleMobileMenu()" title="โปรโมชั่นยางรถยนต์">โปรยางล่าสุด</router-link>
                                        </div>
                                        <router-link :to="routerPath.ARTICLE" @click.native="toggleMobileMenu()" title="บทความยาง-ยานยนต์">บทความ/ข่าวสาร</router-link>
                                        <router-link :to="routerPath.CONTACT" @click.native="toggleMobileMenu()" title="ติดต่อเรา">ติดต่อเรา</router-link>
                                    </section>
                                </perfect-scrollbar>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div class="layout-body">
                <div class="container d-lg-none" v-if="checkDisplaySearchMobile()">
                    <div class="mobile-search">
                        <global-search></global-search>
                    </div>
                </div>
                <slot />
            </div>
        </div>

        <footer class="site-footer">
            <div class="container">
                <div class="tags">
                    <a :href="item.link" v-for="(item, index) in footerTags" :key="index" :title="item.text">
                        {{ item.text }}
                    </a>
                    <!-- <router-link :to="routerPath.TIRE_CALCULATOR" title="คำนวณขนาดยาง">
                        คำนวณขนาดยาง
                    </router-link> -->
                </div>
                
                <div class="wrapper">
                    <div class="navigation">
                        <div class="menu">
                            <label>ข้อมูลและช่วยเหลือ</label>
                            <a :href="routerPath.SELLER_MAIN" title="สำหรับร้านค้า">สำหรับร้านค้า</a>
                            <a href="https://www.yellowtire.com/article/585.HOWTO-SHOPPING-TIRES" title="วิธีค้นหายางรถยนต์">วิธีค้นหายางรถยนต์</a>
                            <a href="https://www.yellowtire.com/article/626.HOWTO-ORDER-TIRES" title="สั่งซื้อยางรถยนต์อย่างไร">สั่งซื้อยางรถยนต์อย่างไร</a>
                            <a href="https://www.yellowtire.com/article/627.PAYMENT-CHANNELS" title="ช่องทางการชำระเงิน">ช่องทางการชำระเงิน</a>
                            <a href="https://www.yellowtire.com/article/608.REVIEW-WARRANTY-TIRES" title="การรับประกันยางรถยนต์">การรับประกันยางรถยนต์</a>
                            <a href="https://www.yellowtire.com/article/725.HOWTO-SHOPPING-TIRES-DELIVERY" title="ซื้อยางแบบจัดส่ง">ซื้อยางแบบจัดส่ง</a>
                            <a href="https://www.yellowtire.com/article/726.HOWTO-SHOPPING-TIRES-INSTALL" title="ซื้อยางแบบติดตั้ง">ซื้อยางแบบติดตั้ง</a>
                        </div>
                        <div class="menu">
                            <label>เยลโลไทร์ดอทคอม</label>
                            <router-link :to="routerPath.ABOUT" title="เกี่ยวกับเยลโลไทร์">เกี่ยวกับเยลโลไทร์</router-link>
                            <router-link :to="routerPath.STATIC_TERM" title="ข้อตกลงและเงื่อนไข">ข้อตกลงและเงื่อนไข</router-link>
                            <router-link :to="routerPath.STATIC_PRIVACY" title="นโยบายความเป็นส่วนตัว">นโยบายความเป็นส่วนตัว</router-link>
                            <router-link :to="routerPath.STATIC_COOKIE" title="นโยบายการใช้คุกกี้">นโยบายการใช้คุกกี้</router-link>
                            <a href="https://www.yellowtire.com/article/724.RETURN-REFUND-POLICY" title="นโยบายการคืนสินค้าและคืนเงิน">นโยบายการคืนสินค้าและคืนเงิน</a>
                        </div>
                        <div class="payment">
                            <label>วิธีการชำระเงิน</label>
                            <div class="logo-group">
                                <div class="icons">
                                    <div class="method">
                                        <img src="@/assets/icon-card-visa.webp" alt="VISA" title="VISA" />
                                    </div>
                                    <div class="method">
                                        <img src="@/assets/icon-card-master.webp" alt="MASTER" title="MASTER" />
                                    </div>
                                    <div class="method">
                                        <img src="@/assets/icon-card-jcb.webp" alt="JCB" title="JCB" />
                                    </div>
                                </div>
                            </div>
                            <div class="bank">
                                <img src="@/assets/app-bank.webp" alt="Bank Associate" title="Bank Associate" />
                                <img src="@/assets/installment-bank.webp" alt="Bank Installment" title="Bank Installment" />
                            </div>
                        </div>
                        <div class="certified">
                            <label>ผ่านการรับรอง</label>
                            <div class="logo">
                                <a href="https://www.trustmarkthai.com/callbackData/popup.php?data=dd45c-35-5-cfad28a82902cf4023a683f7bf6a4f9900243728" target="_blank" class="box">
                                    <img src="@/assets/dbd-regiesterd.webp" alt="DBD Registered" title="DBD Registered" />
                                </a>
                                <div class="box">
                                    <img src="@/assets/logo-ocpb-direct.webp" alt="OCPB DIRECT" title="OCPB DIRECT" />
                                </div>
                            </div>
                        </div>
                        <div class="contact d-lg-none">
                            <label>ติดตาม</label>
                            <a href="https://www.facebook.com/yellowtire" target="_blank" title="facebook"><font-awesome-icon :icon="['fab', 'facebook']" /> Yellowtire</a>
                            <a href="https://www.youtube.com/@yellowtirethailand" target="_blank" title="Youtube Channel"><font-awesome-icon :icon="['fab', 'youtube']" /> Yellowtirethailand</a>
                            <a href="mailto:info@yellowtire.com" target="_blank" title="info@yellowtire.com"><font-awesome-icon :icon="['fas', 'envelope']" /> info@yellowtire.com</a>
                            <span><font-awesome-icon :icon="['fas', 'mobile-alt']" /> +66 (0) 2026 7407</span>
                        </div>
                    </div>

                    <div class="contact d-none d-lg-block">
                        <label>ติดตาม</label>
                        <a href="https://www.facebook.com/yellowtire" target="_blank" title="facebook"><font-awesome-icon :icon="['fab', 'facebook']" /> Yellowtire</a>
                        <a href="https://www.youtube.com/@yellowtirethailand" target="_blank" title="Youtube Channel"><font-awesome-icon :icon="['fab', 'youtube']" /> Yellowtirethailand</a>
                        <a href="mailto:info@yellowtire.com" target="_blank" title="info@yellowtire.com"><font-awesome-icon :icon="['fas', 'envelope']" /> info@yellowtire.com</a>
                        <span><font-awesome-icon :icon="['fas', 'mobile-alt']" /> +66 (0) 2026 7407</span>
                    </div>
                </div>

                <div class="copyright">
                    © 2022 Yellowtire. All rights Reserved
                </div>
            </div>
        </footer>

        <div class="fb-chat-icon" v-if="isShowFacebookIcon">
            <a :href="helper.getFacebookLink()" target="_blank"><b-icon icon="messenger" class="icon"></b-icon> แชท</a>
        </div>

        <cookie-consent />
    </div>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import CookieConsent from '@/components/gadget/CookieConsent';
import GlobalSearch from '@/components/gadget/GlobalSearch';
import MixinContentScroll from '@/components/layouts/mixins/contentScroll';
import MixinImageContext from '@/mixins/imageContext';
import MasterService from '@/services/masterService';
import ProductService from '@/services/productService';
import UserService from '@/services/userService';
import OrderService from '@/services/orderService';

export default {
    mixins: [ MixinContentScroll, MixinImageContext ],
    components: {
        CookieConsent,
        GlobalSearch
    },
	data() {
		return {
			routerPath: RouterPath,
            helper: Helper,
            isShowMobileMenu: false,
            isShowCartIcon: false,
            isShowFacebookIcon: false,
            displayName: '',
            brandMenu: [],
            footerTags: [],
            isShowTireBrandMenu: false,
            isLoadMoreTireBrandMenu: false,
            isShowTireDiameterMenu: false,
            isShowTireTypeMenu: false,
            isShowPromotionMenu: false,
            tireDiameterMenus: [],
            tireTypeMenus: [],
            promotionMenus: []
		};
	},
    mounted() {
        this.initFacebook();
        this.getTopMenu();
        this.loadTireBrand();
        this.loadFooterTags();
        this.setupTracking();
        this.checkCartStatus();
        this.setUserDisplay();

        this.$root.$on('rootSetupTracking', () => {
            this.setupTracking();
        });
        
        this.$root.$on('rootSetCartIcon', () => {
            this.checkCartStatus();
        });

        this.$root.$on('rootSetUserDisplay', () => {
            this.setUserDisplay();
        });

        this.$root.$on('rootToggleFacebookChat', (value) => {
            this.isShowFacebookIcon = value;
        });
    },
    methods: {
        initFacebook() {
            this.isShowFacebookIcon = true;

            if (location.host !== 'yellowtire:8888' 
                && location.host !== 'yellowtire:8080') {
                window.fbAsyncInit = function() {
                    window.FB.init({
                        appId: '493290418802024',
                        autoLogAppEvents : true,
                        xfbml: true,
                        version: 'v14.0'
                    });
                };
            
                (function(d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s); js.id = id;
                    js.src = 'https://connect.facebook.net/th_TH/sdk.js';
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
            }
        },
        clientVersion() {
            return window.clientVersion;
        },
        async getTopMenu() {
            const result = await MasterService.getTopMenu();

            this.tireDiameterMenus = result.data.diameter;
            this.tireTypeMenus = result.data.type;
            this.promotionMenus = result.data.promotion;
        },
        toggleTireDiameterMenu() {
            this.isShowTireDiameterMenu = !this.isShowTireDiameterMenu;
        },
        toggleTireTypeMenu() {
            this.isShowTireTypeMenu = !this.isShowTireTypeMenu;
        },
        togglePromotionMenu() {
            this.isShowPromotionMenu = !this.isShowPromotionMenu;
        },
        checkDisplaySearchMobile() {
            // Hide search if user page
            if (location.pathname.indexOf('/my/') > -1
                || location.pathname.indexOf('/order/receipt/') > -1
                || location.pathname.indexOf('/tire') > -1
                || location.pathname.indexOf('/brand') > -1
                || location.pathname.indexOf('/login') > -1
                || location.pathname.indexOf('/forget-password') > -1
                || location.pathname.indexOf('/register') > -1
                || location.pathname.indexOf('/cart') > -1) {
                return false;
            }

            return true;
        },
        async loadTireBrand() {
            const result = await ProductService.getTireBrand();

            if (result.data) {
                this.brandMenu = result.data;
            }
        },
        async loadFooterTags() {
            const result = await ProductService.getFooterTags();

            if (result.data) {
                this.footerTags = result.data;
            }
        },
        setupTracking() {
            if (localStorage.cookie) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        const data = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        };

                        localStorage.locationData = JSON.stringify(data);
                    });
                }
            }
        },
        toggleMobileMenu() {
            this.isShowMobileMenu = !this.isShowMobileMenu;
        },
        getBrandMenu() {
            return this.isLoadMoreTireBrandMenu ? this.brandMenu : this.brandMenu.slice(0, 6);
        },
        isUserLogin() {
            return UserService.isUserLogin();
        },
        async checkCartStatus() {
            if (this.isUserLogin()) {
                const result = await OrderService.getCartStatus();
                
                if (result.data) {
                    this.isShowCartIcon = result.data.hasCart;
                }
            } else {
                this.isShowCartIcon = false;
            }
        },
        setUserDisplay() {
            if (localStorage.userDisplay) {
                this.displayName = localStorage.userDisplay;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.template-default {
    position: relative;
    overflow: hidden;
	max-width: 100%;
    min-width: 340px;
    min-height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .template-top {
        .site-header {
            background: #4E4C4C;

            .desktop {
                padding: 20px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .logo {
                    width: 260px;

                    @media only screen and (max-width: $screenExtraLarge) {
                        width: 200px;
                    }

                    img {
                        width: inherit;
                        display: block;
                    }
                }

                .top-content {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;

                    @media only screen and (max-width: $screenExtraLarge) {
                        flex: 1;
                        padding-left: 27px;
                    }

                    .search {
                        width: 760px;

                        @media only screen and (max-width: $screenExtraLarge) {
                            width: 100%;
                        }

                        .links {
                            display: flex;
                            justify-content: space-between;
                            margin: 0 -3px 5px;
                            align-items: baseline;

                            a, .dropdown-link {
                                display: inline-block;
                                position: relative;
                                background: #686868;
                                color: #fff;
                                font-size: 13px;
                                text-decoration: none;
                                white-space: nowrap;
                                padding: 5px 10px;
                                margin: 0 3px;
                                @include borderRadius(1000px);
                                @include boxShadow(0 0 10px rgba(0, 0, 0, 0.2));

                                @media only screen and (max-width: $screenExtraLarge) {
                                    font-size: 12px;
                                }

                                @media only screen and (max-width: $screenLarge) {
                                    font-size: 11px;
                                }

                                &:hover {
                                    text-decoration: underline;
                                }

                                button {
                                    margin: 0;
                                    padding: 0;
                                    border: none;
                                    background: none;
                                    outline: none;
                                    color: #fff;
                                }
                            }

                            .sub-menu {
                                display: none;
                                position: absolute;
                                top: 99%;
                                left: 0;
                                margin-top: 1px;
                                z-index: 20;
                                background-color: #777;
                                padding: 1px;
                                @include borderRadius(5px);
                                @include boxShadow(0 0 10px rgba(0, 0, 0, 0.2));

                                a {
                                    display: block;
                                    background: none;
                                    padding: 2px 5px;
                                    margin: 0 3px;
                                    font-size: 11px;
                                    border-bottom: 1px solid #888;
                                    @include borderRadius(0);
                                    @include boxShadow(none);

                                    &:last-child {
                                        border-bottom: none;
                                    }

                                    @media only screen and (max-width: $screenLarge) {
                                        font-size: 11px;
                                    }
                                }

                                &.show {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            .mobile {
                padding: 10px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .group-left {
                    display: flex;
                    align-items: center;
                }

                .menu-icon {
                    color: #fff;
                    font-size: 30px;
                    cursor: pointer;
                    margin-right: 5px;
                }

                .logo {
                    img {
                        height: 36px;
                        display: block;
                    }
                }
            }

            .mobile-menu {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.7);
                z-index: 100000;

                .box-menu {
                    position: fixed;
                    width: 250px;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    padding: 15px;
                    background: #333;
                    z-index: 100001;

                    .close-menu {
                        text-align: right;

                        .menu-icon {
                            color: #777;
                            font-size: 30px;
                        }
                    }

                    .user {
                        border-bottom: 1px solid #666;
                        margin-bottom: 10px;
                        padding-bottom: 10px;

                        a {
                            justify-content: flex-start;
                            color: #ccc;
                        }
                    }
                    
                    a, button, span {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px 0;
                        font-size: 14px;
                        color: #fff;
                        background: none;
                        border: none;
                        outline: none;
                        @include transition(all 0.2s linear);

                        &:hover {
                            opacity: 0.5;
                            text-decoration: none;
                        }

                        .icon {
                            margin-right: 5px;
                        }

                        .toggle-icon {
                            margin-left: 5px;
                        }
                    }

                    .load-more {
                        padding: 5px 10px;
                        font-size: 12px;
                        color: #ccc;
                        border: 1px dashed #999;
                        margin-top: 5px;
                        @include borderRadius(5px);

                        .toggle-icon {
                            margin-left: 5px;
                        }
                    }

                    .sub {
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0 0 5px;

                        a {
                            padding: 0;

                            img {
                                display: block;
                                width: 66px;
                                margin: 0 5px 5px 0;
                            }
                        }
                    }

                    .toggle-menu {
                        flex-wrap: wrap;
                        display: none;
                        margin-bottom: 12px;

                        &.show {
                            display: flex;
                        }

                        a {
                            display: inline-block;
                            padding: 3px 7px;
                            font-size: 12px;
                            margin: 0 5px 5px 0;
                            background: #666;
                        }
                    }
                }
            }

            .menu {
                .user {
                    margin-bottom: 8px;
                    max-width: 150px;
                    text-align: right;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    a {
                        border: none;
                        background: none;
                        outline: none;
                        color: #fff;
                        font-size: 13px;
                        text-decoration: none;
                        margin: 0;

                        @media only screen and (max-width: $screenExtraLarge) {
                            font-size: 12px;
                        }

                        @media only screen and (max-width: $screenLarge) {
                            font-size: 11px;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .gadget {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 5px;
                padding-left: 10px;

                a, button {
                    margin: 0 5px;
                    border: none;
                    background: none;
                    text-decoration: none;
                    padding: 0;
                    color: #fff;
                }

                .icon {
                    font-size: 30px;
                    color: #fff;
                }

                .cart-icon {
                    position: relative;

                    .dot {
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        top: 15px;
                        right: -3px;
                        z-index: 1;
                        background: #ff0000;
                        @include borderRadius(50%);
                    }
                }
            }
        }
    }

    .site-footer {
        background: #333;
        border-bottom: 4px solid $mainColor;
        padding: 20px 0;
        color: #fff;

        .tags {
            margin-bottom: 20px;
            padding: 0 80px;
            display: flex;
            flex-wrap: wrap;

            @media only screen and (max-width: $screenLarge) {
                padding: 0 20px;
            }

            @media only screen and (max-width: $screenMedium) {
                padding: 0;
            }

            a {
                display: inline-block;
                font-size: 12px;
                font-weight: normal;
                line-height: 12px;
                padding: 6px 10px;
                margin: 0 3px 3px 0;
                background: #686868;
                white-space: nowrap;
                color: #fff;
            }
        }

        .wrapper {
            display: flex;
            justify-content: space-between;
            padding: 0 80px;

            @media only screen and (max-width: $screenLarge) {
                padding: 0 20px;
            }

            @media only screen and (max-width: $screenMedium) {
                display: block;
                padding: 0;
            }

            label {
                display: block;
                font-weight: bold;
                font-size: 13px;
                margin-bottom: 20px;
            }

            a, span {
                display: block;
                text-decoration: none;
                font-size: 12px;
                line-height: 24px;
                color: #fff;
                opacity: 0.8;

                svg {
                    color: #fff;
                    margin-right: 3px;
                    width: 16px;
                    font-size: 12px;
                    text-align: left;
                }
            }

            a:hover {
                text-decoration: underline;
            }

            .navigation {
                display: flex;
                flex-wrap: wrap;

                .menu {
                    margin-right: 10px;
                    width: 186px;

                    @media only screen and (max-width: $screenLarge) {
                        width: 175px;
                        margin-right: 0;
                    }

                    @media only screen and (max-width: $screenMedium) {
                        width: 25%;
                        margin: 0 0 20px 0;
                    }

                    @media only screen and (max-width: $screenSmall) {
                        width: 50%;
                    }
                }

                .payment {
                    width: 200px;

                    @media only screen and (max-width: $screenLarge) {
                        width: 160px;
                    }

                    @media only screen and (max-width: $screenMedium) {
                        width: 25%;
                        margin-bottom: 20px;
                    }

                    @media only screen and (max-width: $screenSmall) {
                        width: 50%;
                    }

                    .logo-group {
                        display: flex;

                        @media only screen and (max-width: $screenSmall) {
                            display: block;
                        }

                        .icons {
                            display: flex;
                            flex-wrap: wrap;
                            padding-right: 15px;

                            @media only screen and (max-width: $screenSmall) {
                                padding-right: 0;
                            }

                            img {
                                display: block;
                                height: 20px;
                                margin: 0;
                            }

                            .method {
                                padding: 4px;
                                background: #fff;
                                margin-right: 3px;
                            }
                        }
                    }

                    .bank {
                        margin-top: 5px;

                        img {
                            display: block;
                            width: 135px;
                            margin-bottom: 5px;
                        }
                    }
                }

                .certified {
                    width: 120px;

                    @media only screen and (max-width: $screenMedium) {
                        width: 25%;
                        margin-bottom: 20px;
                    }

                    @media only screen and (max-width: $screenSmall) {
                        width: 50%;
                    }

                    .logo {
                        display: flex;
                        flex-direction: row;

                        img {
                            display: block;
                            height: 40px;
                            margin-bottom: 5px;
                        }

                        .box {
                            padding: 5px;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .copyright {
            color: #9A9898;
            text-align: center;
            font-size: 12px;
            margin-top: 30px;
        }
    }

    .fb-chat-icon {
        position: fixed;
        right: 12px;
        top: auto;
        bottom: 70px;
        z-index: 2147483644;

        a {
            background: #0a8ef8;
            color: #fff;
            width: 97px;
            height: 44px;
            font-size: 18px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            @include boxShadow(rgba(0, 0, 0, 0.15) 0px 4px 12px 0px);
            @include borderRadius(60px);

            .icon {
                font-size: 20px;
                margin-right: 7px;
            }
        }
    }
}

.mobile-search {
    margin: 15px 0;
}
</style>