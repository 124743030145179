import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPath from '@/router/path';
import SellerService from '@/services/sellerService';
import UserService from '@/services/userService';

Vue.use(VueRouter);

function authGuardUser(to, from, next) {
	if(UserService.isUserLogin()) {
		next();
	} else {
		next(RouterPath.LOGIN);
	}
}

function authGuardSeller(to, from, next) {
	if(SellerService.isSellerLogin()) {
		next();
	} else {
		next(RouterPath.SELLER_LOGIN);
	}
}

const routes = [
	{ path: '*', component: () => import('@/views/404.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.NOT_FOUND, component: () => import('@/views/404.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.HOME, component: () => import('@/views/Home.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.SEARCH, component: () => import('@/views/Search.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.TIRE, component: () => import('@/views/ProductList.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.TIRE_BY_CAR, component: () => import('@/views/ProductList.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.TIRE_BY_TYPE, component: () => import('@/views/ProductList.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.TIRE_BRAND, component: () => import('@/views/ProductList.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.TIRE_DETAIL, component: () => import('@/views/Product.vue'), meta: { layout: 'default', alias: 'tireDetail' } },
	{ path: RouterPath.PROMOTION, component: () => import('@/views/PromotionList.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.PROMOTION_DETAIL, component: () => import('@/views/Promotion.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.ARTICLE, component: () => import('@/views/ArticleList.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.ARTICLE_DETAIL, component: () => import('@/views/Article.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.AUTHOR_PROFILE, component: () => import('@/views/Author.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.STORE, component: () => import('@/views/StoreList.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.STORE_DETAIL, component: () => import('@/views/Store.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.STORE_DETAIL_WITH_PRODUCT, component: () => import('@/views/StoreWithProduct.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.NETWORK, component: () => import('@/views/NetworkLanding.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.NETWORK_DETAIL, component: () => import('@/views/Network.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.NETWORK_DETAIL_WITH_PRODUCT, component: () => import('@/views/NetworkWithProduct.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.CART, component: () => import('@/views/Cart.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.RECEIPT, component: () => import('@/views/Receipt.vue'), meta: { layout: 'default' }, beforeEnter: authGuardUser },
	{ path: RouterPath.ABOUT, component: () => import('@/views/About.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.CONTACT, component: () => import('@/views/Contact.vue'), meta: { layout: 'default' } },
	// { path: RouterPath.TIRE_CALCULATOR, component: () => import('@/views/TireCalculator.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.LOGIN, component: () => import('@/views/Login.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.REGISTER, component: () => import('@/views/Register.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.FORGET_PASSWORD, component: () => import('@/views/ForgetPassword.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.MY_BASE, redirect: RouterPath.MY_ORDER },
	{ path: RouterPath.MY_FAVORITE, component: () => import('@/views/Favorite.vue'), meta: { layout: 'default' }, beforeEnter: authGuardUser },
	{ path: RouterPath.MY_ORDER, component: () => import('@/views/users/OrderHistory.vue'), meta: { layout: 'default' }, beforeEnter: authGuardUser },
	{ path: RouterPath.MY_RECEIPT, component: () => import('@/views/Receipt.vue'), meta: { layout: 'default' }, beforeEnter: authGuardUser },
	{ path: RouterPath.MY_RECEIPT_FEE, component: () => import('@/views/ReceiptFee.vue'), meta: { layout: 'default' }, beforeEnter: authGuardUser },
	{ path: RouterPath.MY_PROFILE, component: () => import('@/views/users/Profile.vue'), meta: { layout: 'default' }, beforeEnter: authGuardUser },
	{ path: RouterPath.MY_PASSWORD, component: () => import('@/views/users/Password.vue'), meta: { layout: 'default' }, beforeEnter: authGuardUser },
	{ path: RouterPath.MY_CAR, component: () => import('@/views/users/Car.vue'), meta: { layout: 'default' }, beforeEnter: authGuardUser },
	{ path: RouterPath.MY_PRIVACY, component: () => import('@/views/users/Privacy.vue'), meta: { layout: 'default' }, beforeEnter: authGuardUser },
	{ path: RouterPath.STATIC_TERM, component: () => import('@/views/static/HelpTerm.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.STATIC_TERM_SELL_PRODUCT, component: () => import('@/views/static/HelpTermSellProduct.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.STATIC_TERM_WARRANTY, component: () => import('@/views/static/HelpTermWarranty.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.STATIC_TERM_STORE, component: () => import('@/views/static/HelpTermStore.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.STATIC_INFO_EU, component: () => import('@/views/static/HelpInfoEU.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.STATIC_INFO_TSI, component: () => import('@/views/static/HelpInfoTSI.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.STATIC_PRIVACY, component: () => import('@/views/static/HelpPrivacyPolicy.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.STATIC_COOKIE, component: () => import('@/views/static/HelpCookiePolicy.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.STATIC_SELLER_TERM_INSTALLATION, component: () => import('@/views/static/HelpTermSellerInstallation.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.STATIC_SELLER_TERM_DELIVERY, component: () => import('@/views/static/HelpTermSellerDelivery.vue'), meta: { layout: 'default' } },
	{ path: RouterPath.SELLER_MAIN, redirect: RouterPath.SELLER_DASHBOARD },
	{ path: RouterPath.SELLER_LOGIN, component: () => import('@/views/seller/Login.vue'), meta: { layout: 'seller-blank' } },
	{ path: RouterPath.SELLER_FORGET_PASSWORD, component: () => import('@/views/seller/ForgetPassword.vue'), meta: { layout: 'seller-blank' } },
	{ path: RouterPath.SELLER_REGISTER, component: () => import('@/views/seller/Register.vue'), meta: { layout: 'seller-blank' } },
	{ path: RouterPath.SELLER_FORM, component: () => import('@/views/seller/RegisterProfile.vue'), meta: { layout: 'seller-blank' } },
	{ path: RouterPath.SELLER_PROFILE, component: () => import('@/views/seller/Profile.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_SETTING, component: () => import('@/views/seller/Setting.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_SETTING_PREPAID, component: () => import('@/views/seller/SettingPrepaid.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_DECORATION, component: () => import('@/views/seller/Decoration.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_STAFF, component: () => import('@/views/seller/Staff.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_ORDERS, component: () => import('@/views/seller/Orders.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_ORDERS_SERVICE, component: () => import('@/views/seller/OrderService.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_ORDERS_DELIVERY, component: () => import('@/views/seller/OrderDelivery.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_ORDER_DETAIL, component: () => import('@/views/seller/OrderDetail.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_RECEIPT, component: () => import('@/views/seller/Receipt.vue'), meta: { layout: 'seller-blank' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_DELIVERY_COVER, component: () => import('@/views/seller/DeliveryCover.vue'), meta: { layout: 'seller-blank' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_PRODUCTS, component: () => import('@/views/seller/ProductStock.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_PRODUCTS_SERVICE, component: () => import('@/views/seller/ProductService.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_PRODUCTS_WARRANTY, component: () => import('@/views/seller/ProductWarranty.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_PRODUCTS_PROMOTION, component: () => import('@/views/seller/ProductPromotion.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_PRODUCTS_IMPORT, component: () => import('@/views/seller/ProductImportV2.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_INCOME, component: () => import('@/views/seller/Income.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_REVIEW, component: () => import('@/views/seller/Review.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_HOLIDAY, component: () => import('@/views/seller/Holiday.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_DASHBOARD, component: () => import('@/views/seller/Dashboard.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_REPORT_SALE, component: () => import('@/views/seller/ReportSale.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_REPORT_PRODUCT_REMAIN, component: () => import('@/views/seller/ReportProductRemain.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_REPORT_PROMOTION, component: () => import('@/views/seller/ReportPromotion.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_REPORT_PRODUCT_PROMOTION, component: () => import('@/views/seller/ReportProductPromotion.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_REPORT_PRODUCT_MOVEMENT, component: () => import('@/views/seller/ReportProductMovement.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_REPORT_SYSTEM, component: () => import('@/views/seller/ReportSystem.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_REPORT_CUSTOMER, component: () => import('@/views/seller/ReportCustomer.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },

	{ path: RouterPath.SELLER_PRODUCTS_PROMOTION_V2, component: () => import('@/views/seller/ProductPromotionV2.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
	{ path: RouterPath.SELLER_PRODUCTS_PROMOTION_DETAIL, component: () => import('@/views/seller/ProductPromotionDetail.vue'), meta: { layout: 'seller-menu' }, beforeEnter: authGuardSeller },
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
});

export default router;
